import { helpers } from "vuelidate/lib/validators";

const alphaSpace = helpers.regex("isNameValid", /^[a-zA-Z\u00C0-\u017F_ ]*$/);
const tel = helpers.regex(
  "tel",
  /(0|\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1))((\W*\d\W*){1,10})$/
);
const password = helpers.regex(
  "password",
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/
);

const isNumber = helpers.regex("isNumber", /^[0-9.,]*$/);

export { alphaSpace, tel, password, isNumber };
